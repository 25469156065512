"use client";

import { useEffect, useState } from "react";
import Script from "next/script";
import { isBrowser } from "@local/utils";
import { usePathname } from "next/navigation";
export const GorgiasScript = ({
  id
}: {
  id?: string | null;
}) => {
  const [loaded, setLoaded] = useState(false);
  const pathname = usePathname();
  // const conv = Cookies.get("_o")

  const GORGIAS_IDS = id ? id.split(",") : null;
  const GORGIAS_ID = GORGIAS_IDS && GORGIAS_IDS.length > 0 ? GORGIAS_IDS[0] : null;
  useEffect(() => {
    if (isBrowser() && GORGIAS_ID) {
      setLoaded(true);
    }
  }, [GORGIAS_ID]);
  const verifyPathnameToHideGorgias = (pathname: string) => {
    const gorgiasChatElement = document.getElementById("gorgias-chat-container");
    if (!gorgiasChatElement) return;
    if (pathname.includes("contact-us")) {
      gorgiasChatElement.setAttribute("style", "display: none");
    } else {
      gorgiasChatElement.setAttribute("style", "display: block");
    }
  };
  useEffect(() => {
    verifyPathnameToHideGorgias(pathname);
  }, [pathname]);
  if (!loaded) {
    return null;
  }
  return <Script id="gorgias-chat-widget-install-v3" src={`https://config.gorgias.chat/bundle-loader/${GORGIAS_ID}`} strategy="afterInteractive" onLoad={async () => {
    window.GorgiasChat ? await window.GorgiasChat.init() : await new Promise(function (resolve) {
      window.addEventListener("gorgias-widget-loaded", function () {
        window.GorgiasChat.hidePoweredBy(true);
        //@ts-ignore
        resolve();
      });
    });
    // NECESSARY TO RUN VERIFICATION ON AFTER LOADING FIRST TIME
    verifyPathnameToHideGorgias(pathname);
    // if (conv) {
    //   //@ts-ignore
    //   initGorgiasChatPromise.then(async () => {
    //     window.GorgiasChat.open()
    //   })
    // }
  }} data-sentry-element="Script" data-sentry-component="GorgiasScript" data-sentry-source-file="gorgias.tsx" />;
};